<div class="w-full p-8">
  <div class="flex flex-col">
    <p class="m-0 pt-5 text-center text-[40px] font-weight-500">
      Get a plan tailored <span class="font-semibold">for you Business</span>
    </p>
    <p class="p-0 mt-0 pt-[20px] text-[18px] header-p-tag-color text-center">
      Tailored solutions for businesses of all sizes, with flexible
      subscriptions and industry-leading features.
    </p>
  </div>

  <div class="flex justify-evenly bg-white pt-[40px] pb-[5px] rounded-[32px]">
    <div
      class="flex flex-col justify-between relative w-[50%] pt-[40px] pb-[40px] right-border">
      <div class="pr-[40px] pl-[40px] pb-[20px]" style="visibility: hidden">
        <div class="flex flex-row justify-between">
          <p class="text-[13px] m-0 text-primary-color capitalize">Demo</p>
        </div>
        <p class="text-[24px] font-bold m-0 pt-2">
          $00
          <small class="font-normal text-sm">/ month</small>
        </p>
        <p class="m-0 pt-1 pb-4">
          <small class="car-p-tag-color text-[12px] font-normal">
            Everything that have advanced QR-marketing and strategy needs</small
          >
        </p>

        <div>
          <button
            pButton
            [label]="'Demo'"
            class="normal-btn text-white border-none rounded-md w-[100%] text-[14px] mt-2 pt-3 pb-3"></button>
        </div>
      </div>
      <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">Traceability</p>
      <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">
        <span> Multi-Organizational Tracking </span>
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">QR Code Limit</p>
      <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">User Access</p>
      <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">
        Scan Report Export
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">
        Smart Anti-Counterfeit QR Codes
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">
        Custom Reporting
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">
        Dashboard Features
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">
        QR Code Design Customization
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">
        Customer Engagement Module
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">
        Digital Expiration Counter
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">Support</p>
      <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">
        Compliance Support
      </p>

      <div class="mt-5">
        <p
          class="pl-[30px] pr-[30px] text-[15px] font-semibold pb-3 text-primary-color">
          TOP-UPS
        </p>
        <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">
          QR Code Top-Up
        </p>
        <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">
          Additional Users
        </p>
        <p class="border pl-[30px] pr-[30px] text-[14px] pb-3">
          Additional Products
        </p>
      </div>
    </div>
    @for (plan of plans$(); track plan) {
      @if (!plan.hasPurchasedSubscription) {
        <div
          class="flex flex-col justify-between relative w-[50%] pt-[40px] pb-[40px] left-border right-border">
          <div class="pr-[40px] pl-[40px] pb-[20px]">
            <div class="flex flex-row justify-between">
              <p class="text-[13px] m-0 text-primary-color capitalize">
                {{ plan.productDetails.name }}
              </p>
            </div>
            <p class="text-[24px] font-bold m-0 pt-2">
              {{ plan.amount | currency }}
              <small class="font-normal text-sm"
                >/ {{ plan.interval_count > 1 ? plan.interval_count : '' }}
                {{ plan.interval }}</small
              >
            </p>
            <p class="m-0 pt-1 pb-4">
              <small class="car-p-tag-color text-[12px] font-normal">
                {{ plan.metadata['line1'] || '-' }}
              </small>
            </p>

            <div>
              @if (
                user$()?.subscriptionInfo?.subscriptionStatus !== 'canceled'
              ) {
                <button
                  pButton
                  [label]="getLabel(plan)"
                  (click)="switchSubscription(plan)"
                  class="normal-btn text-white border-none rounded-md w-[100%] text-[14px] mt-2 pt-3 pb-3"></button>
              }
            </div>
          </div>

          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            {{ plan.metadata['list1_Traceability'] || '-' }}
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (
                plan.metadata['list2_Multi-Organizational Tracking'] || ''
              ).trim() === 'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            {{ plan.metadata['list3_QR Code Limit'] || '-' }}
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            {{ plan.metadata['list4_User Access'] || '-' }}
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (plan.metadata['list5_Scan Report Export'] || '').trim() === 'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            {{ plan.metadata['list6_Smart Anti-Counterfeit QR Codes'] || '-' }}
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (plan.metadata['list7_Custom Reporting'] || '').trim() === 'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (plan.metadata['list8_Dashboard Features'] || '').trim() === 'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (
                plan.metadata['list9_QR Code Design Customization'] || ''
              ).trim() === 'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (
                plan.metadata['list10_Customer Engagement Module'] || ''
              ).trim() === 'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (
                plan.metadata['list11_Digital Expiration Counter'] || ''
              ).trim() === 'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            {{ plan.metadata['list12_Support'] || '-' }}
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (plan.metadata['list13_Compliance Support'] || '').trim() ===
              'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>

          <div class="mt-5">
            <p
              class="pl-[30px] pr-[30px] text-[15px] font-semibold pb-3 text-primary-color"></p>
            <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
              @if ((plan.metadata['addOn_0'] || '').trim() !== 'no') {
                <span>{{ plan.metadata['addOn_0'] }}</span>
              } @else {
                <span>-</span>
              }
            </p>
            <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
              @if ((plan.metadata['addOn_1'] || '').trim() !== 'no') {
                <span>{{ plan.metadata['addOn_1'] }}</span>
              } @else {
                <span>-</span>
              }
            </p>
            <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
              @if ((plan.metadata['addOn_2'] || '').trim() !== 'no') {
                <span>{{ plan.metadata['addOn_2'] }}</span>
              } @else {
                <span>-</span>
              }
            </p>
          </div>
        </div>
      } @else {
        <div
          class="active-plan flex flex-col justify-between relative w-[50%] rounded-[12px] pt-[40px] pb-[40px]">
          <div class="pr-[40px] pl-[40px] pb-[20px]">
            <div class="flex flex-row justify-between">
              <p class="text-[13px] m-0 text-primary-color capitalize">
                {{ plan.productDetails.name }}
              </p>
            </div>
            <p class="text-[24px] font-bold m-0 pt-2">
              {{ plan.amount | currency }}
              <small class="font-normal text-sm"
                >/ {{ plan.interval_count > 1 ? plan.interval_count : '' }}
                {{ plan.interval }}</small
              >
            </p>
            <p class="m-0 pt-1 pb-4">
              <small class="car-p-tag-color text-[12px] font-normal">
                {{ plan.metadata['line1'] }}</small
              >
            </p>

            <div>
              <button
                pButton
                [label]="'You\'re on ' + plan.productDetails.name"
                class="active-btn text-black border-none rounded-md w-[100%] text-[14px] mt-2 pt-3 pb-3 pointer-events-none"></button>
            </div>
          </div>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            {{
              plan.metadata['list1_Traceability']
                ? plan.metadata['list1_Traceability']
                : '-'
            }}
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (plan.metadata['list2_Multi-Organizational Tracking'].trim() ||
                '') === 'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            {{
              plan.metadata['list3_QR Code Limit']
                ? plan.metadata['list3_QR Code Limit']
                : '-'
            }}
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            {{
              plan.metadata['list4_User Access']
                ? plan.metadata['list4_User Access']
                : '-'
            }}
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (plan.metadata['list5_Scan Report Export'].trim() || '') === 'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            {{
              plan.metadata['list6_Smart Anti-Counterfeit QR Codes']
                ? plan.metadata[
                    'list6_Smart Anti-Counterfeit QR
          Codes'
                  ]
                : '-'
            }}
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (plan.metadata['list7_Custom Reporting'].trim() || '') === 'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (plan.metadata['list8_Dashboard Features'].trim() || '') === 'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (plan.metadata['list9_QR Code Design Customization'].trim() ||
                '') === 'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (plan.metadata['list10_Customer Engagement Module'].trim() ||
                '') === 'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (plan.metadata['list11_Digital Expiration Counter'].trim() ||
                '') === 'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            {{
              plan.metadata['list12_Support']
                ? plan.metadata['list12_Support']
                : '-'
            }}
          </p>
          <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
            @if (
              (plan.metadata['list13_Compliance Support'].trim() || '') ===
              'yes'
            ) {
              <span class="pi pi-check text-black text-[16px]"></span>
            } @else {
              <span>-</span>
            }
          </p>
          <div class="mt-5">
            <p
              class="pl-[30px] pr-[30px] text-[15px] font-semibold pb-3 text-primary-color"></p>
            <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
              @if ((plan.metadata['addOn_0'].trim() || '') !== 'no') {
                <span>{{ plan.metadata['addOn_0'] }}</span>
              } @else {
                <span>-</span>
              }
            </p>
            <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
              @if ((plan.metadata['addOn_1'].trim() || '') !== 'no') {
                <span>{{ plan.metadata['addOn_1'] }}</span>
              } @else {
                <span>-</span>
              }
            </p>
            <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
              @if ((plan.metadata['addOn_2'].trim() || '') !== 'no') {
                <span>{{ plan.metadata['addOn_2'] }}</span>
              } @else {
                <span>-</span>
              }
            </p>
          </div>
        </div>
      }
    }
    <div
      class="flex flex-col justify-between relative w-[50%] pt-[40px] pb-[40px] right-border left-border">
      <div class="pr-[40px] pl-[40px] pb-[20px]">
        <div class="flex flex-row justify-between">
          <p class="text-[13px] m-0 text-primary-color capitalize">
            Enterprise Plan
          </p>
        </div>
        <p class="text-[24px] font-bold m-0 pt-2">
             <small class="font-normal text-sm">Please contact Sales Team </small>
        </p>
        <p class="m-0 pt-1 pb-4">
          <small class="car-p-tag-color text-[12px] font-normal">
            Everything that have advanced QR-marketing and strategy needs
          </small>
        </p>

        <div>
          <a
            href="https://www.regenesis.ag/contact/"
            target="_blank"
            rel="noopener noreferrer">
            <button
              pButton
              [label]="'Talk to Sales Team'"
              class="bg-black text-white border-none rounded-md w-[100%] text-[14px] mt-2 pt-3 pb-3"></button>
          </a>
        </div>
      </div>
      <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
        Unlimited
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
        <span class="pi pi-check text-black text-[16px]"></span>
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
        Unlimited
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
        Unlimited
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
        <span class="pi pi-check text-black text-[16px]"></span>
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
        $100 Additional cost
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
        <span class="pi pi-check text-black text-[16px]"></span>
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
        <span class="pi pi-check text-black text-[16px]"></span>
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
        <span class="pi pi-check text-black text-[16px]"></span>
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
        <span class="pi pi-check text-black text-[16px]"></span>
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
        Set custom date
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
        Dedicated support team
      </p>
      <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
        Dedicated support team
      </p>
      <div class="mt-5">
        <p
          class="pl-[30px] pr-[30px] text-[15px] font-semibold pb-3 text-primary-color"></p>
        <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
          Unlimited
        </p>
        <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
          $15 USD per user/mo
        </p>
        <p class="border pl-[30px] pr-[30px] text-[14px] text-center pb-3">
          $100 USD per product
        </p>
      </div>
    </div>
  </div>

  <div
    class="flex flex-row justify-between items-center billind-card mt-[40px] pt-[50px] pb-[50px] pl-[40px] pr-[40px] rounded-[12px]">
    <div>
      <div>
        <p class="text-[28px] font-bold p-0 m-0">Billing Cycle process?</p>
        <p class="text-[20px] billing-text-color p-0 m-0 mt-3">
          Bi-Annual subscription, payments deducted monthly.
        </p>
      </div>
    </div>
    <div>
      <a
        href="https://www.regenesis.ag/contact/"
        target="_blank"
        rel="noopener noreferrer">
        <button
          pButton
          [label]="'Know more'"
          class="know-more-btn text-primary-color rounded-[10px] w-[100%] text-[16px] mt-2 pl-[80px] pr-[80px] pt-[19px] pb-[19px] font-semibold"></button>
      </a>
    </div>
  </div>
</div>
