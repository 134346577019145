@if (actionType === 'APPROVE') {
  <p-button
    [disabled]="disabled"
    [text]="true"
    (click)="actionClicked.emit('APPROVE')"
    severity="success"
    icon="pi pi-check"
    pTooltip="Approve"
    tooltipPosition="top" />
} @else if (actionType === 'REJECT') {
  <p-button
    [disabled]="disabled"
    [text]="true"
    severity="danger"
    (click)="actionClicked.emit('REJECT')"
    icon="pi pi-times"
    pTooltip="Reject"
    tooltipPosition="top" />
} @else if (actionType === 'RESEND') {
  <p-button
    [disabled]="disabled"
    [text]="true"
    severity="info"
    (click)="actionClicked.emit('RESEND')"
    icon="pi pi-refresh"
    pTooltip="Resend"
    tooltipPosition="top" />
} @else if (actionType === 'DELETE') {
  <p-button
    [disabled]="disabled"
    [text]="true"
    severity="danger"
    (click)="actionClicked.emit('DELETE')"
    icon="pi pi-trash"
    pTooltip="Delete"
    tooltipPosition="top" />
} @else if (actionType === 'EDIT') {
  <p-button
    [disabled]="disabled"
    [text]="true"
    severity="info"
    (click)="actionClicked.emit('EDIT')"
    icon="pi pi-pencil"
    pTooltip="Edit"
    tooltipPosition="top" />
} @else if (actionType === 'QR') {
  <p-button
    [disabled]="disabled"
    [text]="true"
    severity="secondary"
    (click)="actionClicked.emit('QR')"
    icon="pi pi-qrcode"
    pTooltip="QR Settings"
    tooltipPosition="top" />
} @else if (actionType === 'SETTINGS') {
  <p-button
    [disabled]="disabled"
    [text]="true"
    severity="secondary"
    (click)="actionClicked.emit('SETTINGS')"
    icon="pi pi-cog"
    pTooltip="Product Settings"
    tooltipPosition="top" />
} @else if (actionType === 'DOWNLOAD') {
  <p-button
    [disabled]="disabled"
    [text]="true"
    severity="secondary"
    (click)="actionClicked.emit('DOWNLOAD')"
    icon="pi pi-download"
    pTooltip="Download"
    tooltipPosition="top" />
} @else if (actionType === 'BULK') {
  <p-button
    [disabled]="disabled"
    [text]="true"
    severity="secondary"
    (click)="actionClicked.emit('BULK')"
    icon="pi pi-upload"
    pTooltip="Bulk Upload"
    tooltipPosition="top" />
}
