import { CTE, KDE, PaginationParams } from '../general-store/general.model';
import { OrganizationKDEsParams } from '../organizations-store/organizations.model';
import {
  AddProductParams,
  BulkUpdateProductTemplateComponentParams,
  BulkUpdateProductTemplateModulesParams,
  ProductDesignTemplatePageModuleDataContent,
  UpdateProductCTEParams,
  UpdateProductParams,
  UpdateProductQrSettingsParams,
  UpdateProductTemplateModuleParams,
  UpdateProductTemplateParams,
} from './products.model';

const CONTEXT = '[ProductStore]';

export class GetProductList {
  static readonly type = `${CONTEXT} Get Product List`;
  constructor(public payload: PaginationParams) {}
}

export class DeleteProduct {
  static readonly type = `${CONTEXT} Delete Product`;
  constructor(public id: number) {}
}

export class SearchProducts {
  static readonly type = `${CONTEXT} Search Product`;
  constructor(public payload: PaginationParams) {}
}

export class ResetHeaders {
  static readonly type = `${CONTEXT} Reset Headers`;
}

export class GetProductItemDataList {
  static readonly type = `${CONTEXT} Get Product Item Data List`;
  constructor(public payload: PaginationParams) {}
}

export class DeleteProductItemData {
  static readonly type = `${CONTEXT} Delete Product Item Data`;
  constructor(public id: number) {}
}

export class GetAllProducts {
  static readonly type = `${CONTEXT} Get All Products`;
}

export class AddProduct {
  static readonly type = `${CONTEXT} Add Product`;
  constructor(public payload: AddProductParams) {}
}

export class UpdateProduct {
  static readonly type = `${CONTEXT} Update Product`;
  constructor(
    public id: number,
    public payload: UpdateProductParams
  ) {}
}

export class GetProduct {
  static readonly type = `${CONTEXT} Get Product`;
  constructor(public payload: number) {}
}

export class UpdateProductKDEs {
  static readonly type = `${CONTEXT} Update Product KDEs`;
  constructor(public payload: OrganizationKDEsParams) {}
}

export class AddProductKDE {
  static readonly type = `${CONTEXT} Add Product KDE`;
  constructor(public kde: KDE) {}
}

export class GetProductKdeValues {
  static readonly type = `${CONTEXT} Get Product KDE Values`;
  constructor(public kdeId: number) {}
}

export class UpdateProductKDE {
  static readonly type = `${CONTEXT} Update Product KDE`;
  constructor(
    public id: number | undefined,
    public kde: KDE
  ) {}
}

export class UpdateProductCTE {
  static readonly type = `${CONTEXT} Update Product CTE`;
  constructor(
    public id: number,
    public productId: number,
    public payload: UpdateProductCTEParams
  ) {}
}

export class DeleteProductCTE {
  static readonly type = `${CONTEXT} Delete Product CTE`;
  constructor(public cteId: number) {}
}

export class DeleteProductKDE {
  static readonly type = `${CONTEXT} Delete Product KDE`;
  constructor(
    public cte: CTE,
    public kdeID: number
  ) {}
}

export class UpdateProductQrSettings {
  static readonly type = `${CONTEXT} Update Product QR Settings`;
  constructor(
    public id: number,
    public payload: UpdateProductQrSettingsParams
  ) {}
}

export class UpdateProductTemplate {
  static readonly type = `${CONTEXT} Update Product Template`;
  constructor(
    public id: number,
    public payload: UpdateProductTemplateParams
  ) {}
}

export class BulkUpdateProductTemplateModules {
  static readonly type = `${CONTEXT} Bulk Update Product Template Modules`;
  constructor(public payload: BulkUpdateProductTemplateModulesParams) {}
}

export class BulkUpdateProductTemplateComponents {
  static readonly type = `${CONTEXT} Bulk Update Product Template Components`;
  constructor(public payload: BulkUpdateProductTemplateComponentParams[]) {}
}

export class AddProductTemplateModule {
  static readonly type = `${CONTEXT} Add Product Template Module`;
  constructor(
    public productId: number,
    public id: number
  ) {}
}

export class UpdateProductTemplateModule {
  static readonly type = `${CONTEXT} Update Product Template Module`;
  constructor(
    public id: number,
    public payload: UpdateProductTemplateModuleParams
  ) {}
}

export class DeleteProductTemplateModule {
  static readonly type = `${CONTEXT} Delete Product Template Module`;
  constructor(public id: number) {}
}

export class UpdateProductTemplateComponent {
  static readonly type = `${CONTEXT} Update Product Template Component`;
  constructor(
    public id: number,
    public payload: ProductDesignTemplatePageModuleDataContent
  ) {}
}

export class AddProductTemplateComponent {
  static readonly type = `${CONTEXT} Add Product Template Component`;
  constructor(public payload: ProductDesignTemplatePageModuleDataContent) {}
}

export class DeleteProductTemplateComponent {
  static readonly type = `${CONTEXT} Delete Product Template Component`;
  constructor(
    public id: number,
    public moduleId: number
  ) {}
}

export class ClearProductData {
  static readonly type = `${CONTEXT} Clears Product Data`;
}
