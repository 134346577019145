import { Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login/login.component';
import { RequestDemoPageComponent } from './pages/request-demo/request-demo.component';
import { OTPPageComponent } from './pages/otppage/otppage.component';
import { SignUpPageComponent } from './pages/sign-up-page/sign-up-page.component';
import { OrganizationListComponent } from './pages/organizations/organization-list/organization-list.component';
import { OrganizationDemoRequestsComponent } from './pages/organizations/organization-demo-requests/organization-demo-requests.component';
import { ScanStatistics } from './pages/dashboards/scan-statistics/scan-statistics.component';
import { AdminUserListComponent } from './pages/users/admin-user-list/admin-user-list.component';
import { VendorListComponent } from './pages/vendors/vendor-list/vendor-list.component';
import { UserListComponent } from './pages/users/user-list/user-list.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { RolesComponent } from './pages/settings/roles/roles.component';
import { CtesKdesComponent } from './pages/settings/ctes-kdes/ctes-kdes.component';
import { ProductListComponent } from './pages/products/product-list/product-list.component';
import { ProductAddEditComponent } from './pages/products/product-add-edit/product-add-edit.component';
import { AddProductFormComponent } from './components/form-components/add-product-form/add-product-form.component';
import { QrOrderListComponent } from './pages/qr/qr-order-list/qr-order-list.component';
import { ProductItemDataListComponent } from './pages/products/product-item-data-list/product-item-data-list.component';
import { canActivateTeam } from './guards/role.guard';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { NoAccessComponent } from './components/shared-components/no-access/no-access.component';
import { ProductExpirationComponent } from './pages/dashboards/product-expiration/product-expiration.component';
import { PaymentMethodsComponent } from './pages/settings/payment-methods/payment-methods.component';
import { PlansComponent } from './pages/subscription/plans/plans.component';
import { SubscribeComponent } from './pages/subscription/subscribe/subscribe.component';
import { ProfileSecurityComponent } from './pages/settings/profile-security/profile-security.component';
import { LandingComponent } from './pages/dashboards/landing/landing.component';
import { TrackDashboardComponent } from './pages/dashboards/track-products/track-products.component';

export const routes: Routes = [
  {
    path: 'organizations',
    children: [
      {
        path: 'list',
        component: OrganizationListComponent,
        data: {
          title: 'Organization List',
        },
      },
      {
        path: 'demo-requests',
        component: OrganizationDemoRequestsComponent,
        data: {
          title: 'Organization Demo Requests',
        },
      },
    ],
  },
  {
    path: 'vendors',
    children: [
      {
        path: 'list',
        component: VendorListComponent,
        data: {
          title: 'Vendor List',
          requiredPermissions: ['list-vendor'],
        },
        canActivate: [canActivateTeam],
      },
    ],
  },
  {
    path: 'users',
    children: [
      {
        path: 'list',
        component: UserListComponent,
        data: {
          title: 'User List',
          requiredPermissions: ['list-user'],
        },
        canActivate: [canActivateTeam],
      },
      {
        path: 'admin',
        component: AdminUserListComponent,
        data: {
          title: 'Admin User List',
        },
      },
    ],
  },

  {
    path: 'subscription',
    children: [
      {
        path: 'plans',
        children: [
          {
            path: '',
            component: PlansComponent,
            data: {
              title: 'Subscription Plans',
              requiredPermissions: ['view-subscription'],
            },
            canActivate: [canActivateTeam],
          },
          {
            path: 'subscribe/:id',
            component: SubscribeComponent,
            data: {
              title: 'Subscribe',
              requiredPermissions: ['create-subscription'],
            },
            canActivate: [canActivateTeam],
          },
        ],
      },
    ],
  },

  {
    path: 'products',
    children: [
      {
        path: 'list',
        component: ProductListComponent,
        data: {
          title: 'Product List',
          requiredPermissions: ['list-product'],
        },
        canActivate: [canActivateTeam],
      },
      {
        path: 'data',
        component: ProductItemDataListComponent,
        data: {
          title: 'Product Item Data List',
          requiredPermissions: ['list-product-data'],
        },
        canActivate: [canActivateTeam],
      },
      {
        path: 'add',
        component: ProductAddEditComponent,
        data: {
          title: 'Add New Product',
          requiredPermissions: ['add-product'],
        },
        canActivate: [canActivateTeam],
        children: [
          { path: '', redirectTo: 'product-passport', pathMatch: 'full' },
          {
            path: 'product-passport',
            component: AddProductFormComponent,
            data: {
              title: 'Product Passport',
            },
          },
        ],
      },
      {
        path: 'edit/:id',
        component: ProductAddEditComponent,
        data: {
          title: 'Edit Product',
          requiredPermissions: ['edit-product'],
        },
        canActivate: [canActivateTeam],
        children: [
          { path: '', redirectTo: 'product-passport', pathMatch: 'full' },
          {
            path: 'product-passport',
            component: AddProductFormComponent,
            data: {
              title: 'Product Passport',
            },
          },
          {
            path: 'define-ctes',
            component: CtesKdesComponent,
            data: {
              title: 'Define CTEs',
            },
          },
        ],
      },
    ],
  },

  {
    path: 'settings',
    component: SettingsComponent,
    data: {
      title: 'Settings',
      requiredPermissions: ['list-cte-kde', 'list-role', 'view-subscription'],
    },
    canActivate: [canActivateTeam],
    children: [
      { path: '', redirectTo: 'profile-security', pathMatch: 'full' },
      {
        path: 'payment-methods',
        component: PaymentMethodsComponent,
      },
      {
        path: 'roles',
        component: RolesComponent,
      },
      {
        path: 'profile-security',
        component: ProfileSecurityComponent,
      },
      {
        path: 'ctes-kdes',
        component: CtesKdesComponent,
      },
    ],
  },
  {
    path: 'dashboards',
    children: [
      { path: '', redirectTo: 'landing', pathMatch: 'full' },
      {
        path: 'landing',
        component: LandingComponent,
        data: {
          title: 'Dashboard',
        },
      },
      {
        path: 'product-expiration',
        component: ProductExpirationComponent,
        data: {
          title: 'Product Expiration',
        },
      },
      {
        path: 'scan-statistics',
        component: ScanStatistics,
        data: {
          title: 'Scan Statistics',
        },
      },
      {
        path: 'track-products',
        component: TrackDashboardComponent,
        data: {
          title: 'Track Products',
        },
      },
    ],
  },
  {
    path: 'qr',
    children: [
      {
        path: 'qr-orders',
        children: [
          {
            path: 'list',
            component: QrOrderListComponent,
            data: {
              title: 'QR Order List',
              requiredPermissions: ['list-qr-order'],
            },
            canActivate: [canActivateTeam],
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: LoginPageComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'otp',
    component: OTPPageComponent,
  },
  {
    path: 'demo',
    component: RequestDemoPageComponent,
  },
  {
    path: 'accept-invitation',
    component: SignUpPageComponent,
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
  },
];
