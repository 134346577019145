import {
  Component,
  effect,
  EventEmitter,
  Input,
  Output,
  HostListener,
  ElementRef,
  Renderer2,
  OnInit,
} from '@angular/core';
import { OrderListModule } from 'primeng/orderlist';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { OrganizationStateSelectors } from '../../../../store/organizations-store/organizations.selectors';
import { Store } from '@ngxs/store';
import { AsyncPipe } from '@angular/common';
import {
  CTE,
  SidebarData,
} from '../../../../store/general-store/general.model';
import {
  UpdateOrganizationKDEs,
  UpdateOrganizationCTEs,
  DeleteOrganizationCTE,
  GetOrganizationCTEs,
} from '../../../../store/organizations-store/organizations.actions';
import {
  OrganizationKDEsParams,
  OrganizationCTEsParams,
} from '../../../../store/organizations-store/organizations.model';
import { RightFormBaseComponent } from '../../../components/shared-components/right-form-base/right-form-base.component';
import { AddCTEFormComponent } from '../../../components/form-components/add-cte-form/add-cte-form.component';
import { GeneralStateSelectors } from '../../../../store/general-store/general.selectors';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AddKDEFormComponent } from '../../../components/form-components/add-kde-form/add-kde-form.component';
import { Observable } from 'rxjs';
import { ProductStateSelectors } from '../../../../store/products-store/products.selectors';
import {
  DeleteProductKDE,
  UpdateProduct,
  DeleteProductCTE,
} from '../../../../store/products-store/products.actions';
import { Product } from '../../../../store/products-store/products.model';
import { TableActionButtonComponent } from '../../../components/shared-components/table-action-button/table-action-button.component';
import { ConfirmationService } from 'primeng/api';
import { isS3Url } from '../../../services/global.service';
import { AccessDirective } from '../../../directives/role-based-access.directive';
import { DeleteKDE } from '../../../../store/general-store/general.actions';
import { RightFormFooterComponent } from '../../../components/shared-components/right-form-footer/right-form-footer.component';

@Component({
  selector: 'app-ctes-kdes',
  standalone: true,
  imports: [
    OrderListModule,
    AccordionModule,
    ButtonModule,
    AsyncPipe,
    RightFormBaseComponent,
    AddCTEFormComponent,
    AddKDEFormComponent,
    TableActionButtonComponent,
    AccessDirective,
    RightFormFooterComponent,
  ],
  templateUrl: './ctes-kdes.component.html',
  styleUrl: './ctes-kdes.component.scss',
})
export class CtesKdesComponent implements OnInit {
  @Input() isProductCtes = false;
  @Input() product: Product | null = null;
  @Output() updatedCTEs = new EventEmitter<CTE[]>();
  @Output() actionSuccess = new EventEmitter();

  processing$ = this.store.selectSignal(ProductStateSelectors.isProcessing);

  ctes$: Observable<CTE[]> | undefined;
  selectedCte: CTE | null = null;
  ctes: CTE[] = [];

  currentCteId = 0;
  isProductKDE = false;
  sidebar: SidebarData = {
    type: '',
    visible: false,
    title: '',
    data: null,
    width: 'w-50',
  };

  kdeSidebar: SidebarData = {
    visible: false,
    title: '',
    type: 'ADD',
    data: null,
    width: 'w-50',
  };

  private overlayIndex = 100;

  isS3Url = isS3Url;
  constructor(
    private store: Store,
    private confirmationService: ConfirmationService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.store
      .select(GeneralStateSelectors.shouldHideSideMenu)
      .pipe(takeUntilDestroyed())
      .subscribe(hide => {
        if (hide) {
          this.sidebar.visible = false;
          this.kdeSidebar.visible = false;
        }
      });

    this.store.dispatch(new GetOrganizationCTEs());

    effect(() => {
      this.ctes$ = this.isProductCtes
        ? this.store.select(ProductStateSelectors.getSelectedProductCTEs)
        : this.store.select(OrganizationStateSelectors.getOrganizationCTEs);

      this.ctes$?.subscribe(res => {
        if (res.length > 0) {
          this.ctes = res;
        }
      });
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (
      event.key === 'Enter' &&
      this.isTopmostOverlay() &&
      !this.processing$()
    ) {
      this.updateProduct();
    }
  }

  isTopmostOverlay(): boolean {
    const allOverlays = document.querySelectorAll('[data-overlay-index]');
    const currentIndex = parseInt(
      this.el.nativeElement.getAttribute('data-overlay-index') || '0',
      10
    );
    const maxIndex = Math.max(
      ...Array.from(allOverlays).map(el =>
        parseInt(el.getAttribute('data-overlay-index') || '0', 10)
      )
    );
    return currentIndex === maxIndex;
  }

  ngOnInit() {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'data-overlay-index',
      this.overlayIndex.toString()
    );
  }

  listReordered(cte: CTE) {
    const orderedKDERequest: OrganizationKDEsParams = {
      kdes: [],
    };
    let order = 0;
    const KDEs = cte.productKdes ? cte.productKdes : cte.kdes;
    for (const kde of KDEs) {
      orderedKDERequest.kdes.push({
        id: kde.id,
        data: {
          order,
          organizationCteId: cte.id,
        },
      });
      order++;
    }
    if (cte.productKdes) {
      const kdes = [];
      for (const kde of KDEs) {
        kdes.push({
          ...kde,
          order,
        });
        order++;
      }
      this.ctes$?.subscribe(ctes => {
        const index = ctes.findIndex(c => c.id === cte.id);
        ctes[index] = cte;
        this.ctes = ctes;
      });
    } else {
      this.store.dispatch(new UpdateOrganizationKDEs(orderedKDERequest));
    }
  }

  preventSelection(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  addKDE(cte: CTE, event: Event) {
    this.currentCteId = cte.id;
    this.isProductKDE = false;
    this.selectedCte = cte;
    if (cte.productKdes) {
      this.isProductKDE = true;
    }
    this.kdeSidebar = {
      title: `Create KDE for ${this.selectedCte.title}`,
      visible: true,
      type: 'ADD',
      width: 'w-50',
    };
    event.preventDefault();
    event.stopPropagation();
  }

  updateKde(_kde: boolean) {
    this.kdeSidebar.visible = false;
  }

  orderCTEs(orgCTEs: CTE[], emitEvent = false) {
    if (!emitEvent) {
      const orderedCTERequest: OrganizationCTEsParams = {
        ctes: [],
      };
      let order = 0;
      for (const cte of orgCTEs) {
        orderedCTERequest.ctes.push({
          id: cte.id,
          data: { order },
        });
        order++;
      }
      this.store.dispatch(new UpdateOrganizationCTEs(orderedCTERequest));
    } else {
      let order = 0;
      const ctes: CTE[] = [];
      for (const cte of orgCTEs) {
        ctes.push({
          ...cte,
          order,
        });
        order++;
      }
      this.ctes = ctes;
    }
  }

  updateProduct() {
    const updateProductParams = {
      productName: this.product?.productName,
      productDescription: this.product?.productDescription,
      brandName: this.product?.brandName,
      measurementUnit: this.product?.measurementUnit,
      packagingUnit: this.product?.packagingUnit,
      pricingUnit: this.product?.pricingUnit,
      gtin: this.product?.gtin,
      fdaClassified: this.product?.fdaClassified,
      logo: this.product?.logo,
      links: this.product?.links,
      productImages: this.product?.productImages.map(image => {
        return {
          id: image.id,
          name: image.name,
          url: image.url,
        };
      }),
      productCtes: this.product?.productCtes.map(pcte => {
        const localCte = this.ctes.find(c => c.id === pcte.id);
        return {
          id: pcte.id,
          title: pcte.title,
          description: pcte.description,
          isDefault: pcte.isDefault,
          organizationCteId: pcte.organizationCteId,
          type: pcte.type,
          icon: pcte.icon,
          order: localCte?.order,
          productKdes: pcte.productKdes.map(pkde => {
            const localKde = localCte?.productKdes.find(k => k.id === pkde.id);
            return {
              id: pkde.id,
              title: pkde.title,
              description: pkde.description,
              isDefault: pkde.isDefault,
              inputType: pkde.inputType,
              inputTypeOptions: pkde.inputTypeOptions,
              gs1: pkde.gs1,
              fsma204: pkde.fsma204,
              order: localKde?.order ?? pkde.order,
            };
          }),
        };
      }),
    };
    this.store
      .dispatch(new UpdateProduct(this.product!.id, updateProductParams))
      .subscribe(() => {
        this.actionSuccess.emit();
      });
  }

  actionClickedCTE(event: string, id: number, data: CTE) {
    if (event === 'DELETE') {
      this.confirmationService.confirm({
        header: `${event} CTE`,
        message: `Are you sure want to delete this CTE?`,
        icon: 'pi pi-trash',
        acceptLabel: 'Confirm',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          if (this.isProductCtes) this.store.dispatch(new DeleteProductCTE(id));
          else this.store.dispatch(new DeleteOrganizationCTE(id));
        },
      });
    } else if (event === 'EDIT') {
      this.sidebar = {
        id,
        type: 'EDIT',
        visible: true,
        title: 'Edit CTE',
        data,
        width: 'w-50',
      };
    }
  }

  actionClickedKDE(event: string, kdeID: number, data: CTE) {
    if (event === 'DELETE') {
      this.confirmationService.confirm({
        header: `${event} KDE`,
        message: `Are you sure want to delete this KDE?`,
        icon: 'pi pi-trash',
        acceptLabel: 'Confirm',
        acceptIcon: 'pi',
        rejectVisible: false,
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          if (data?.productKdes)
            this.store.dispatch(new DeleteProductKDE(data, kdeID));
          else this.store.dispatch(new DeleteKDE(data, kdeID));
        },
      });
    } else if (event === 'EDIT') {
      if (data?.productKdes) {
        this.isProductKDE = true;
      }
      this.currentCteId = data.id;
      this.selectedCte = data;
      this.kdeSidebar = {
        id: kdeID,
        visible: true,
        title: 'Edit KDE',
        type: 'EDIT',
        data,
        width: 'w-50',
      };
    }
  }
}
